 <template>
  <v-container class="pa-0" fluid>
    <v-container>
      <!-- header -->
      <v-sheet height="85vh" class="rounded-xl" color="accent">
        <video-bg
          :sources="[require('@/assets/home/videos/presentation.mp4')]"
          class="rounded-xl"
          style="max-height: 85vh"
        >
          <v-row
            class="fill-height"
            style="background-color: rgba(0, 0, 0, 0.5)"
            no-gutters
          >
            <v-col cols="12" sm="8" md="6" class="px-12 d-flex align-center">
              <v-img :src="require('@/assets/home/header.png')" />
            </v-col>

            <v-col class="pa-6 d-flex align-end justify-end">
              <v-btn
                v-for="(item, i) in social"
                class="rounded-lg mx-2"
                target="_blank"
                :href="item.url"
                :key="i"
                large
                icon
                dark
              >
                <v-icon large>
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </video-bg>
      </v-sheet>
    </v-container>

    <!-- news -->
    <!--  <section class="py-12">
      <v-container>
        <v-row class="align-center justify-space-around" no-gutters>
          <span
            class="
              text-h4 text-xl-h3
              font-weight-black font-italic
              overtext--text
            "
          >
            ÚLTIMAS NOTÍCIAS
          </span>

          <v-spacer />

          <a class="text-body-1 text-xl-h6 font-weight-regular overtext--text">
            IR PARA NOTÍCIAS
            <v-icon class="mb-1">mdi-arrow-right</v-icon>
          </a>
        </v-row>

        <v-row>
          <v-col v-for="(item, index) in 3" cols="4" :key="index">
            <v-card color="surface">
              <v-responsive :aspect-ratio="16 / 9" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section> -->

    <!-- intro -->
    <section class="altback py-12">
      <v-container class="my-6 my-xl-12">
        <v-row class="align-center" no-gutters>
          <v-col cols="12" md="6" class="pr-md-12 mb-12 mb-md-0">
            <h2
              class="
                text-h6 text-xl-h5
                font-weight-bold
                subtext--text
                font-italic
              "
            >
              Somos a
            </h2>

            <h1
              class="
                text-h4 text-xl-h3
                font-weight-black
                overtext--text
                font-italic
                mb-4
              "
            >
              ARENA JOGUE FÁCIL
            </h1>

            <p
              class="text-h6 text-xl-h5 font-weight-regular subtext--text mb-8"
            >
              Possuímos tudo o que você precisa para se tornar um profissional
              do e-sports. Equipamento, consoles, periféricos e ambientes da
              mais alta tecnologia, tudo pensado para seu entretenimento, paixão
              e campeonatos. Confira todos os nossos Serviços!
            </p>

            <v-btn color="gradient" to="/about" large dark> Saiba mais </v-btn>
          </v-col>

          <v-col cols="12" md="6" class="pl-md-12">
            <v-img
              :src="require('@/assets/home/arena.jpg')"
              class="rounded-lg"
              :aspect-ratio="16 / 9"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- carousel -->
    <section class="py-12">
      <v-container class="my-6 my-xl-12">
        <!-- meet -->
        <v-row class="justify-center align-center mb-12" no-gutters>
          <v-icon color="primary" x-large left>mdi-chevron-triple-down</v-icon>

          <div class="d-flex flex-column align-center font-italic mx-4">
            <h2 class="text-h5 text-xl-h4 font-weight-regular overtext--text">
              conheça nosso
            </h2>

            <h1 class="text-h3 text-xl-h2 font-weight-black overtext--text">
              ESPAÇO
            </h1>
          </div>

          <v-icon color="primary" x-large right>mdi-chevron-triple-down</v-icon>
        </v-row>

        <!-- carousel -->
        <v-carousel
          v-model="carousel"
          hide-delimiters
          height="65vh"
          class="rounded-xl mb-12"
        >
          <v-carousel-item v-for="(item, i) in items" :key="i">
            <v-img :src="item.src" height="100%">
              <div class="d-flex justify-center fill-height subtle-gradient">
                <div
                  class="text-h5 text-xl-h4 font-weight-black white--text py-3"
                >
                  <span class="header-carousel font-italic">
                    {{ item.title }}
                  </span>
                </div>
              </div>
            </v-img>
          </v-carousel-item>
        </v-carousel>

        <!-- discover -->
        <v-row class="justify-center align-center">
          <div class="d-flex flex-column align-center">
            <v-icon color="primary" x-large>mdi-chevron-down</v-icon>

            <span class="text-h5 font-weight-regular overtext--text">
              descrubra mais
            </span>
          </div>
        </v-row>
      </v-container>
    </section>

    <!-- gaming -->
    <section class="altback py-12">
      <v-container class="my-6 my-xl-12">
        <v-row class="align-center justify-center mb-12 pb-12">
          <v-col
            cols="10"
            md="7"
            xl="6"
            :class="$vuetify.breakpoint.smAndDown || 'left-item'"
          >
            <v-card class="left-radius" color="surface">
              <v-img :src="require('@/assets/home/game-center.png')" />
            </v-card>
          </v-col>

          <v-col
            cols="10"
            md="5"
            xl="4"
            :class="$vuetify.breakpoint.smAndDown || 'right-item'"
          >
            <v-card class="gradient right-radius">
              <section class="pa-8 d-flex flex-column">
                <h1
                  class="
                    text-h4 text-xl-h3
                    font-weight-black font-italic
                    white--text
                    mb-4
                  "
                >
                  GAME CENTER
                </h1>

                <p
                  class="
                    text-h6 text-xl-h5
                    font-weight-regular
                    white--text
                    mb-0
                  "
                >
                  Alcance a vitória! Sinta a emoção de um Pro player com o
                  melhor espaço gamer Equipamentos da mais alta tecnologia: PCs,
                  Xbox series X, PlayStation 5, cadeiras gamers e periféricos
                  profissionais à sua disposição.
                </p>
              </section>
            </v-card>
          </v-col>
        </v-row>

        <!--  consoles  and computers -->
        <v-row class="justify-center" no-gutters>
          <v-col cols="12" xl="10">
            <v-row class="align-center justify-space-around">
              <v-col class="zoom" cols="6" md="3">
                <v-img
                  :src="require('@/assets/home/ps5.jpg')"
                  class="rounded-lg"
                />

                <div class="text-center text-h5 font-weight-bold mt-4">
                  PlayStation 5
                </div>
              </v-col>

              <v-col class="zoom" cols="6" md="3">
                <v-img
                  :src="require('@/assets/home/pc.jpg')"
                  class="rounded-lg"
                />

                <div class="text-center text-h5 font-weight-bold mt-4">
                  Computadores
                </div>
              </v-col>

              <v-col class="zoom" cols="6" md="3">
                <v-img
                  :src="require('@/assets/home/xbox.jpg')"
                  class="rounded-lg"
                />

                <div class="text-center text-h5 font-weight-bold mt-4">
                  Xbox Series X
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- stream and vip room -->
    <section class="py-12">
      <v-container class="my-6 my-xl-12">
        <v-row class="mb-12" no-gutters>
          <v-col cols="12" md="6" class="pr-md-12 mb-12 mb-md-0">
            <v-img
              :src="require('@/assets/home/stream.jpg')"
              class="rounded-lg mb-8"
              :aspect-ratio="16 / 9"
            />

            <h1
              class="
                text-h4 text-xl-h3
                font-weight-black font-italic
                overtext--text
                mb-4
              "
            >
              Salas de Stream
            </h1>

            <p
              class="text-h6 text-xl-h5 font-weight-regular subtext--text mb-0"
            >
              Salas acústicas equipadas com o que há de mais alta tecnologia
              para realização de suas transmissões. Temos 2 salas, preparadas
              para transmissões de PC e console, ambas com alta qualidade em
              vídeo, iluminação e som, para que seus conteúdos tenham qualidade
              profissional.
            </p>
          </v-col>

          <v-col cols="12" md="6" class="pl-md-12">
            <v-img
              :src="require('@/assets/home/vip.jpg')"
              class="rounded-lg mb-8"
              :aspect-ratio="16 / 9"
            />

            <h1
              class="
                text-h4 text-xl-h3
                font-weight-black font-italic
                overtext--text
                mb-4
              "
            >
              Salas VIP
            </h1>

            <p
              class="text-h6 text-xl-h5 font-weight-regular subtext--text mb-0"
            >
              Espaço reservado com máquinas incríveis, proporcionando mais
              privacidade para nossos jogadores. Contamos com 10 máquinas
              profissionais em nossa sala VIP A e 5 em nossa VIP B, chame seu
              time inteiro e venha jogar. Aproveite cada detalhe do nosso setup
              profissional.
            </p>
          </v-col>
        </v-row>

        <!-- <div align="center">
          <v-btn color="gradient" to="/schedules" large dark>
            Agende seu horário
          </v-btn>
        </div> -->
      </v-container>
    </section>

    <!-- e-sports -->
    <section class="altback py-12">
      <v-container class="my-6 my-xl-12">
        <v-row class="align-center justify-space-around" no-gutters>
          <v-col cols="12" md="6" class="pr-md-12 mb-12 mb-md-0">
            <v-card color="surface">
              <v-responsive :aspect-ratio="16 / 9" />
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="pl-md-12">
            <h1
              class="
                text-h4 text-xl-h3
                font-weight-black font-italic
                overtext--text
                mb-4
              "
            >
              ARENA E-SPORTS
            </h1>

            <p
              class="text-h6 text-xl-h5 font-weight-regular subtext--text mb-0"
            >
              Venha participar de nossas competições de jogos virtuais e explore
              todas as suas possibilidades competitivas. São diversos os
              campeonatos que rolam e já rolaram por aqui: FIFA, Counter-Strike,
              DOTA, Free Fire, League of legends e muito mais. E aí? Tá a fim de
              ser nosso próximo ganhador?
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- restaurant / coffe / bar -->
    <section class="py-12">
      <v-container class="my-6 my-xl-12">
        <v-row class="align-center" no-gutters>
          <v-col cols="12" md="6" class="pr-md-12 mb-12 mb-md-0">
            <h1
              class="
                text-h4 text-xl-h3
                font-weight-black font-italic
                overtext--text
                mb-4
              "
            >
              RESTAURANTE, CAFÉ & BAR
            </h1>

            <p
              class="text-h6 text-xl-h5 font-weight-regular subtext--text mb-8"
            >
              Que tal tomar um drink com a galera? Fazer um belo lanche ou uma
              deliciosa refeição? Aqui temos um menu selecionado, da melhor
              qualidade para as suas refeições.
            </p>

            <v-btn color="gradient" to="/menu" large dark>
              Conheça o Cardápio
            </v-btn>
          </v-col>

          <v-col cols="12" md="6" class="pl-md-12">
            <v-row>
              <v-col cols="6" class="pt-12">
                <v-img
                  :src="require('@/assets/home/menu/01.png')"
                  class="rounded-lg mb-6"
                  :aspect-ratio="4 / 3"
                />

                <v-img
                  :src="require('@/assets/home/menu/02.png')"
                  class="rounded-lg"
                  :aspect-ratio="4 / 3"
                />
              </v-col>

              <v-col cols="6">
                <v-img
                  :src="require('@/assets/home/menu/03.png')"
                  class="rounded-lg mb-6"
                  :aspect-ratio="4 / 3"
                />

                <v-img
                  :src="require('@/assets/home/menu/04.png')"
                  class="rounded-lg"
                  :aspect-ratio="4 / 3"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- geek store -->
    <section class="altback py-12">
      <v-container class="my-6 my-xl-12">
        <v-row class="align-center mb-12" no-gutters>
          <v-col cols="12" md="6" class="pr-md-12 mb-12 mb-md-0">
            <v-row>
              <v-col cols="6">
                <v-img
                  :src="require('@/assets/home/store/01.png')"
                  class="rounded-lg mb-6"
                  :aspect-ratio="4 / 3"
                />

                <v-img
                  :src="require('@/assets/home/store/02.png')"
                  class="rounded-lg"
                  :aspect-ratio="4 / 3"
                />
              </v-col>

              <v-col cols="6" class="pt-12">
                <v-img
                  :src="require('@/assets/home/store/03.png')"
                  class="rounded-lg mb-6"
                  :aspect-ratio="4 / 3"
                />

                <v-img
                  :src="require('@/assets/home/store/04.png')"
                  class="rounded-lg"
                  :aspect-ratio="4 / 3"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" class="pl-md-12">
            <h1
              class="
                text-h4 text-xl-h3
                font-weight-black font-italic
                overtext--text
                mb-4
              "
            >
              LOJA GEEK
            </h1>

            <p
              class="text-h6 text-xl-h5 font-weight-regular subtext--text mb-0"
            >
              Na nossa loja, temos os melhores produtos Geek e Nerd do Brasil.
              Presentes criativos como: canecas, camisetas, capachos, mousepads,
              almofadas, chaveiros, itens colecionáveis, funkos e muitos outros
              produtos exclusivos.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- barbearia -->
    <section class="py-12">
      <v-container class="my-6 my-xl-12">
        <v-row class="align-center mb-12 pb-12" no-gutters>
          <v-col cols="12" md="6" class="pr-md-12 mb-12 mb-md-0">
            <p
              class="
                text-h4 text-xl-h3
                font-weight-black font-italic
                overtext--text
              "
            >
              BARBEARIA
            </p>

            <p
              class="text-h6 text-xl-h5 font-weight-regular subtext--text mb-8"
            >
              Nossa Arena conta com uma barbearia que proporciona uma
              experiência relaxante e de qualidade para seu momento de upgrade.
              Realize corte e penteado de cabelo, recorte da barba e bigode e
              outros cuidados afins com profissionais de confiança. Aqui barba,
              cabelo e bigode são coisas sérias.
            </p>

          <!--   <v-btn color="gradient" to="/schedules" large dark>
              Agende seu horário
            </v-btn> -->
          </v-col>

          <v-col cols="12" md="6" class="pl-md-12">
            <v-img
              :src="require('@/assets/home/barber.jpg')"
              class="rounded-lg"
              :aspect-ratio="16 / 9"
            />
          </v-col>
        </v-row>

        <v-row class="justify-center" no-gutters>
          <v-col cols="12" xl="10">
            <v-row class="justify-space-around align-center">
              <v-col
                v-for="(item, index) in barbers"
                class="zoom"
                cols="6"
                md="4"
                lg="3"
                :key="index"
              >
                <a
                  :href="`https://www.instagram.com/${item.instagram}/`"
                  class="text-decoration-none"
                  target="_blank"
                >
                  <v-img
                    :src="require(`@/assets/barbers/${item.instagram}.png`)"
                    class="rounded-lg"
                  >
                    <div class="d-flex justify-start align-end fill-height">
                      <v-sheet
                        class="py-1 pl-4 pr-8 clip gradient mb-6"
                        color="primary"
                        light
                      >
                        <span class="white--text text-subtitle-1">
                          @{{ item.instagram }}
                        </span>
                      </v-sheet>
                    </div>
                  </v-img>
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
import VideoBg from "vue-videobg";

export default {
  data() {
    return {
      social: [
        {
          label: "Twitch",
          url: "https://www.twitch.tv/arenajoguefacil",
          icon: "mdi-twitch",
        },
        {
          label: "Instagram",
          url: "https://www.instagram.com/arenajoguefacil/",
          icon: "mdi-instagram",
        },
      ],
      carousel: 0,
      items: [
        {
          title: "ESPAÇO GAMER",
          src: require("@/assets/home/tour/area-gamer.png"),
        },
        {
          title: "SALAS DE STREAM",
          src: require("@/assets/home/tour/stream.png"),
        },
        {
          title: "SALAS VIP",
          src: require("@/assets/home/tour/vip.png"),
        },
        {
          title: "BARBEARIA",
          src: require("@/assets/home/tour/barbearia.png"),
        },
        {
          title: "RESTAURANTE",
          src: require("@/assets/home/tour/restaurante.png"),
        },
        { title: "BAR", src: require("@/assets/home/tour/bar.png") },
        { title: "CAFÉ", src: require("@/assets/home/tour/cafe.png") },
        { title: "LOJA", src: require("@/assets/home/tour/loja.png") },
      ],
      barbers: [
        {
          instagram: "jairbarber13",
          name: "Jair",
        },
        {
          instagram: "alifesantos_",
          name: "Alife",
        },
      ],
    };
  },

  components: {
    VideoBg,
  },
};
</script>

<style lang="scss" scoped>
.clip {
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
}

.left-item {
  margin-right: -32px;
}

.right-item {
  margin-left: -32px;
}

.left-radius {
  border-radius: 32px 0px 32px 0px;
}

.right-radius {
  border-radius: 0px 0px 32px 0px;
}

.header-carousel {
  letter-spacing: 6px;
}
</style>

